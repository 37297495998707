/* usage:

const { navVisible } = useNavigationMode()
const dispatch = useNavigationModeDispatch()

const doSomething = () => dispatch({type: 'setPopupVisibility', payload: { visible: true }})
*/

import React from 'react'

interface State {
  content?: 'instagram' | 'tiktok' | 'mobileBrowser'
  link?: string
  filename?: string
}

export type PopupActionType = 'setPopupContent'

interface PopupAction {
  type: PopupActionType
  payload?: State
}

type Dispatch = (action: PopupAction) => void

type PopupProviderProps = {
  children: React.ReactNode
}

const initialState: State = {
  content: undefined,
  link: undefined,
  filename: undefined,
}
const PopupContext = React.createContext<State | undefined>(initialState)
const PopupDispatchContext = React.createContext<Dispatch | undefined>(undefined)

function PopupReducer(state: State, action: PopupAction): State {
  switch (action.type) {
    case 'setPopupContent': {
      return {
        ...state,
        content: action.payload?.content || initialState.content,
        link: action.payload?.link || initialState.link,
        filename: action.payload?.filename || initialState.filename,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function PopupProvider({children}: PopupProviderProps) {
  const [state, dispatch] = React.useReducer(PopupReducer, initialState)
  return (
    <PopupContext.Provider value={state}>
      <PopupDispatchContext.Provider value={dispatch}>
        {children}
      </PopupDispatchContext.Provider>
    </PopupContext.Provider>
  )
}

function usePopup() {
  const context = React.useContext(PopupContext)
  if (context === undefined) {
    throw new Error('usePopup must be used within a PopupProvider')
  }
  return context
}

function usePopupDispatch() {
  const context = React.useContext(PopupDispatchContext)
  if (context === undefined) {
    throw new Error('usePopupDispatch must be used within a PopupProvider')
  }
  return context
}

export { PopupProvider, usePopup, usePopupDispatch }